import React, { useEffect } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { commonService } from '../../../services/common';
import { CHECK_API_ENV } from '../../../config/apipath';

const dashboardId = CHECK_API_ENV === "production" ?  "ecf4cb40-bb6f-4aa3-879b-b5f2c95b9d1d" : "9a3b6918-9177-4306-a2ae-7c5d6634b6ef" ;

async function getToken(widgetIsCollapsed) {

  const response = await commonService.supersetApi(dashboardId);
  const res = response?.data?.token;
  const supersetUrl = response?.data?.supersetBaseUrl;

embedDashboard({
  id: dashboardId,  // given by the Superset embedding UI
  supersetDomain: supersetUrl,
  mountPoint: document.getElementById("superset-container"), // html element in which iframe render
  fetchGuestToken: () => res,
  dashboardUiConfig: { hideTitle: true }
});

const iframe = document.querySelector("iframe");
if (iframe) {
  iframe.style.width =  widgetIsCollapsed ? '69vw' : "92vw"; // Set the width as needed
  iframe.style.minHeight = '75vh'; // Set the height as needed
}
}

const SGSuperset = ({widgetIsCollapsed}) => {
  useEffect(() => {
    getToken(widgetIsCollapsed);
  });

  return (
    <div className="App">
      <div id='superset-container'></div>
    </div>
  );
}

export default SGSuperset;
