import React, { useEffect } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { commonService } from '../../../services/common';
import { CHECK_API_ENV } from '../../../config/apipath';

const dashboardId = CHECK_API_ENV === "production" ? "671db989-e8e7-4508-951e-fada9d0f12d5" : "166c9937-87fc-44c0-9fa6-12e2f292fa96" ;

async function getToken(widgetIsCollapsed) {

  const response = await commonService.supersetApi(dashboardId);
  const res = response?.data?.token;
  const supersetUrl = response?.data?.supersetBaseUrl;

embedDashboard({
  id: dashboardId,  // given by the Superset embedding UI
  supersetDomain: supersetUrl,
  mountPoint: document.getElementById("superset-container"), // html element in which iframe render
  fetchGuestToken: () => res,
  dashboardUiConfig: { hideTitle: true }
});

const iframe = document.querySelector("iframe");
if (iframe) {
  iframe.style.width =  widgetIsCollapsed ? '69vw' : "92vw"; // Set the width as needed
  iframe.style.minHeight = '75vh'; // Set the height as needed
}
}

const LHPSuperset = ({widgetIsCollapsed}) => {
  useEffect(() => {
    getToken(widgetIsCollapsed);
  });

  return (
    <div className="App">
      <div id='superset-container'></div>
    </div>
  );
}

export default LHPSuperset;
