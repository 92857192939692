import React, { useState, useEffect } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import CustomDropdown from '../../CustomDropdown';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import { useDispatch , useSelector} from 'react-redux';
import { setApiError, showLoaderAction } from '../../../../storeManager/common/actions';
import { catchErrorHandler, debounce } from '../../../../services/commonUtils';
import SearchBar from '../../../SearchBar';
import Table from '../../Table';
import TableColumns from './columns';
import { breadcrumbTransformer } from '../../../../services/commonUtils';
import { dashBoardService } from '../../../../pages/Dashboard/dashoardService';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import successIcon from '../../../../assets/images/successIcon.svg'
import failIcon from '../../../../assets/images/red-info-icon.svg'
import searchIcon from '../../../../assets/images/search-icon.svg'



const DidiSGSelectionModal = ({ modalParameters: { blockId, callBackFn, activeSmallGroup, dep, activeUpcmId = null, isReassign = false, showDropdDown }, closePopup }) => {

    const dispatch = useDispatch();
    const { isApiError } = useSelector(state => state.commonReducer)

    const [selectedDidis, setSelectedDidis] = useState([]);

    const [tableData, setTableData] = useState([])

    const [errMsg, setErrMsg] = useState('');
    const [inputValue, setInputValue] = useState('')

    const [selectedUpcm, setSelectedUpcm] = useState()
    const [upcmOptionList, setUpcmOptionList] = useState([]);
    const [blockDataList, setBlockDataList] = useState([])
    const [districtDataList, setDistrictDataList] = useState([])
    const [selectedBlock, setSelectedBlock] = useState(null)
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [didiSearch, setDidiSearch] = useState('');
    const [didiSearchedList, setDidiSearchedList] = useState([]);

    const { roleName, referenceId } = dep

    useEffect(() => {
        getDistrictBlockList(roleName, dep.referenceId[0]?.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const handleDistrictChange = value => {
        setSelectedDidis([])
        getDistrictBlockList("District", value)
        setSelectedDistrict(value)
        setSelectedBlock(null)
        setUpcmOptionList([])
        setTableData([])
        setSelectedUpcm(null)
    }
    const handleBlockChange = value => {
        setTableData([])
        setSelectedDidis([])
        setSelectedBlock(value)
        fetchUpcmList(value)

    }

    const getDistrictBlockList = async (type, refId) => {
        dispatch(showLoaderAction(true));
        try {

            const res = await dashBoardService.getBreadCrumbApi(type, refId);
            const resObj = res.data;
            if (resObj?.status === "SUCCESS") {

                const dropDownData = breadcrumbTransformer(
                    resObj?.data?.localeResponse,
                    type
                );
                if (type === "State") {
                    setDistrictDataList(dropDownData);
                    handleDistrictChange(dropDownData[0]?.value)
                } else if (type === "District" && dropDownData?.length > 0) {
                    setBlockDataList(dropDownData);
                    handleBlockChange(dropDownData[0]?.value)

                } else if (type === "Block" || roleName === 'Block') {
                    handleBlockChange(referenceId[0].id)
                } else {
                    dispatch(showLoaderAction(false));
                    setBlockDataList([]);
                    setSelectedBlock(null)
                }
            }

        } catch (error) {
            dispatch(showLoaderAction(false));

            catchErrorHandler(error, dispatch(setApiError()));
        }
    };

    const fetchUpcmList = async (id) => {
        dispatch(showLoaderAction(true));
        try {
            const response = await commonService.getBlockUpcmList(id);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                let updatedUpcmList = res?.data;

                const upcmList = updatedUpcmList?.map(item => ({
                    value: item.id,
                    htmlName: `<div class="upcm-list"><span class="op-name">${item.upcmName} (${item.phoneNumber})</span> <i class="didi-icon"></i> <span class="count">${item.totalDidiCount}</span></div>`,
                    name: `${item.upcmName}`,
                    key: item.id,
                    upcmName: item.upcmName,
                    count: item.totalDidiCount
                }))
                setUpcmOptionList(upcmList)
                if (activeUpcmId) {
                    handleUpcmChange(activeUpcmId, upcmList)
                }
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
        }
    };

    const handleUpcmChange = (value, upcmList = upcmOptionList) => {

        setSelectedUpcm(upcmList?.find(x => x.value === value))
        fetchUngroupedDidi(value)

    }

    const handleSearchFilter = (value) => {

        setDidiSearch(value)
        const filteredList = tableData.filter(x => x.didiName.toLowerCase().includes(value.toLowerCase()))
        setDidiSearchedList(filteredList)
    }

    const fetchUngroupedDidi = async (value) => {

        try {
            const response = await commonService.getUngroupedDidiList(value);
            const res = response.data;
            if (res?.message === "SUCCESS") {
                if (Array.isArray(res?.data?.didiList) && res?.data?.didiList?.length > 0) {
                    setTableData(res?.data?.didiList)
                } else {
                    setTableData([])
                }

            } else {

                dispatch(showLoaderAction(false));

            }

        } catch (error) {
            dispatch(showLoaderAction(false));

            catchErrorHandler(error, dispatch(setApiError()));
        }
    }

    const handleTableSelect = (e, value, isAllSelected = false) => {
        let UpdatedSelectedDidis = [...selectedDidis];
        if (isAllSelected) {
            const didiList = [];
            value.forEach(el => {
                didiList.push(el.didiId)
            })
            setSelectedDidis(didiList)
        }
        else {
            if (e.target.checked)
                UpdatedSelectedDidis.push(value.didiId);
            else
                UpdatedSelectedDidis = UpdatedSelectedDidis.filter(o => o !== value.didiId);
            setSelectedDidis(UpdatedSelectedDidis)
        }

    }

    const handleDidiId = (value) => {
        setSelectedDidis([])
        let flag = false;
        let _didis = []
        if (inputValue.length > 0) {
            const regex = new RegExp(/^\d+(,\d+)*$/g);

            if (value?.length === 0 || !value) {
                setErrMsg('')
            } else if (!regex.test(value)) {
                setErrMsg('Please enter valid Didi Ids. Only digits and comma allowed.')
                flag = true;

            } else {
                const _invalidIds = []
                _didis = value.split(',')

                const _ungroupedDidids = tableData.map(_x => _x.didiId)
                _didis.forEach(_id => {
                    if (!_ungroupedDidids.includes(parseInt(_id))) {
                        _invalidIds.push(_id)
                    }
                })
                if (_invalidIds.length > 0) {
                    flag = true
                    setErrMsg(`Either the Didi ID entered is not part of the UPCM or already assigned to the exiting Small group. Invalid Didi Ids - ${_invalidIds} `)
                } else {
                    setErrMsg('')
                }

            }
        }
        return { hasError: flag, didiIds: _didis };
    }

    const assignDidiToSmallGroup = async () => {

        const { hasError, didiIds } = handleDidiId(inputValue)

        if (!hasError) {

            try {
                dispatch(showLoaderAction(true));
                const payload = {
                    "beneficiaryIds": [...selectedDidis, ...didiIds],
                    "sourceSmallGroupId": activeSmallGroup?.id,
                    "action": "ASSIGN",
                    "targetSmallGroupId": ""
                }
                const response = await commonService.smallGroupDidiMapping(payload);
                const res = response.data;
                if (res?.message === "SUCCESS") {

                    dispatch(showLoaderAction(false));
                    if (res?.data?.status) {

                        dispatch(openModal(MODALS.SUCCESS_MODAL,
                            {
                                parentClass: "center-modal confirmation-modal",
                                info: res?.data?.message,
                                icon: successIcon,
                                showActionBtn: false
                            }))

                        callBackFn()
                    } else {
                        dispatch(openModal(MODALS.SUCCESS_MODAL,
                            {
                                parentClass: "center-modal confirmation-modal",
                                info: res?.data?.message,
                                icon: failIcon,
                                showActionBtn: false
                            }))
                    }

                } else {

                    dispatch(showLoaderAction(false));

                }

            } catch (error) {
                dispatch(showLoaderAction(false));

                catchErrorHandler(error, dispatch(setApiError()),
                {
                    actionType: "UPDATE",
                    actionStatusType:"ERROR",
                    dataChangeDetails: [{
                        "entityId": [...selectedDidis, ...didiIds],
                        "entityName": "Didi",
                        "fieldName": "Small Group",
                        "oldValue": "null",
                        "newValue": activeSmallGroup
                    }
                    ]
                });
            }
        }
    }

    if(isApiError) {
        closePopup()
     }

    return <div>
        <ModalBody>


            <>
                <div className='action-row-container'>
                    <div className='action-row' >
                        {showDropdDown?.showDistrict && roleName === "District" ?
                            <div className='dropdown-wrap'>{referenceId[0]?.name}</div>
                            : districtDataList?.length > 0 &&
                            <div className='dropdown-label-wrap'>
                                <span className='dropdown-label'>District</span>
                                <CustomDropdown
                                    style={{ "width": "200px" }}
                                    showSearchBar={true}
                                    placeHolder="Select District"
                                    onChange={(value) => handleDistrictChange(value)}
                                    options={districtDataList}
                                    value={selectedDistrict}
                                />
                            </div>
                        }

                        {showDropdDown?.showBlock && roleName === "Block" ?
                            <div className='dropdown-wrap'>{referenceId[0]?.name}</div>
                            : blockDataList.length > 0 ?
                                <div className='dropdown-label-wrap'>
                                    <span className='dropdown-label'>Block</span>
                                    <CustomDropdown
                                        style={{ "width": "200px" }}
                                        showSearchBar={true}
                                        placeHolder="Select Block"
                                        onChange={(value) => handleBlockChange(value)}
                                        options={blockDataList}
                                        value={selectedBlock}
                                    />
                                </div>
                                :
                                <div className='dropdown-label-wrap'><p className='dropdown-error'>Blocks not available</p></div>
                        }
                        {
                            upcmOptionList?.length > 0 ? <div className='dropdown-label-wrap upcm-dropdown'>
                                <span className='dropdown-label'>UPCM</span>
                                <CustomDropdown
                                    style={{ "width": "400px" }}
                                    showSearchBar={true}
                                    placeHolder="Select UPCM"
                                    onChange={(value) => handleUpcmChange(value)}
                                    options={upcmOptionList}
                                    value={selectedUpcm?.value}
                                    className="upcm-selection-dropdown"
                                    isHtmlOption={true}

                                />
                            </div>
                                :
                                <div className='dropdown-label-wrap'><p>Sorry, there is no UPCM available at the moment.</p></div>
                        }
                        <SearchBar
                            handleOnChange={debounce((e) => handleSearchFilter(e.target.value), 500)}
                            placeholder='Search Didi Name'
                        />
                        {selectedDidis?.length > 0 && <p className='selection-text'>{selectedDidis?.length} Didis Selected</p>}
                    </div>
                </div>
                <br />

                <div className='sg-mapping-list-outer'>
                    {tableData.length > 0 ?
                        <>
                            <div className='didi-id-container'>
                                <p>Enter Didi Ids (Comma Separated) </p>
                                <div className='dropdown-label-wrap'>
                                    <span className='dropdown-label'>Didi Id</span>
                                    <input
                                        type='text'
                                        placeHolder='i.e. 1653,1873'
                                        className={errMsg.length ? 'error' : ''}
                                        disabled={selectedDidis.length > 0}
                                        onChange={(e) => { setInputValue(e.target.value); setErrMsg('') }}
                                    />
                                </div>
                                <p className='err-msg'>{errMsg}</p>
                            </div>

                            <Table
                                className='pending-table sg-mapping-list'
                                columns={TableColumns()}
                                data={didiSearch ? didiSearchedList : tableData}
                                checkbox={true}
                                valueKey='didiId'
                                selectedValues={selectedDidis}
                                selectAll={false}
                                handleChange={handleTableSelect}
                                disabled={inputValue.length > 0 ? true : false}
                            />
                        </>
                        :
                        <div className='no-data'><img src={searchIcon} alt="search" /><p>{selectedUpcm ? "No more ungrouped didis found" : "Select UPCM to view didis list"}</p></div>
                    }
                </div>
            </>

        </ModalBody>
        <ModalFooter>
            <button className="secondary-btn ml" onClick={closePopup}>
                Cancel
            </button>
            <button disabled={selectedDidis.length <= 0 && inputValue.length <= 0} className="primary-btn" onClick={assignDidiToSmallGroup}>
                + Add didis to group
            </button>
        </ModalFooter>
    </div>


}
export default DidiSGSelectionModal   