import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Common/Header";
import WidgetSlider from "../../components/WidgetSlider";
import "../Dashboard/style.css"
import './style.scss';
import FallbackComp from "../../components/Common/FallbackComp";
import SurveyProgressSuperset from "../BaselineDashboard/SurveyProgressSuperset";
import PatInsights from "../Dashboard/PatInsights";
import CSGSuperset from "./CSGSuperset";
import LHPSuperset from "./LHPSuperset";
import SGSuperset from "./SGSuperset";
import HamletSurveySupersetDashboard from "../BaselineDashboard/HamletSurveySupersetDashboard";
import BaselineSurveySupersetDashboard from "../BaselineDashboard/BaselineSurveySupersetDashboard";

const AnalyticsDashboard = () => {
  const { referenceValue, roleValue, isLeftArrow, isRightArrow } = useSelector((state) => state.commonReducer);
  const { roleName, referenceId, referenceName, referenceId: id, referenceId: refId } = JSON.parse(localStorage.getItem("userAuth")) || "";
  const refobj = refId?.map((i) => {
    return {
      value: i.name,
      id: i.id,
    };
  });

  let defaultWidgetChange = {
    id: 1,
    name: "Consumption Support Grant Dashboard",
    type: "CSGSuperset",
    pdfType: 'CSGSuperset',
    description: 'Progress of selection process across various stages',
  }

  const [showExcelCta, setShowExcelCta] = useState(false);
  // const [houseHoldStageType, setHouseHoldStageType] = useState("HouseholdSize");
  const [summaryRole, setSummaryRole] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [summary, setSummary] = useState([]);
  const [stateName, setStateName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [toggleType, setToggleType] = useState(true);
  const [question, setQuestion] = useState((''));
  const [sourceData, setSourceData] = useState([]);
  const [slide, setSlide] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [sectionToggle, setSectionToggle] = useState(['District', 'Block'].includes(roleName) ? 'Chart View' : 'Map View');
  const [sectionToggle, setSectionToggle] = useState('Chart View');
  const [widgetChange, setWidgetChange] = useState(defaultWidgetChange)
  const [currentWidgetType, setCurrentWidgetType] = useState(defaultWidgetChange.type)
  const [widgetIsCollapsed, setWidgetIsCollapsed] = useState(true);



  const widgetArray = [
    {
      id: 1,
      name: "PAT Insights",
      type: "patInsights",
      pdfType: 'patInsights',
      description: 'Distribution across Section 2 questions for all didis who have been rejected in PAT Section 2 ',

      content: <PatInsights slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    },
    {
      id: 2,
      name: "Baseline Completion Summary",
      type: "surveyProgressSuperset",
      pdfType: 'surveyProgressSuperset',
      description: 'Progress of selection process across various stages',
      content: <SurveyProgressSuperset slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    },
    {
      id: 3,
      name: "Baseline Survey Dashboard",
      type: "baselineSurveySuperset",
      pdfType: 'baselineSurveySuperset',
      description: 'Progress of selection process across various stages',

      content: <BaselineSurveySupersetDashboard slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    }, {
      id: 4,
      name: "Hamlet Survey Dashboard",
      type: "hamletSurveySuperset",
      pdfType: 'hamletSurveySuperset',
      description: 'Progress of selection process across various stages',

      content: <HamletSurveySupersetDashboard slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    },
    {
      id: 5,
      name: "Small Group Dashboard",
      type: "SGSuperset",
      pdfType: 'SGSuperset',
      description: 'Progress of selection process across various stages',
      content: <SGSuperset slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    },
    {
      id: 6,
      name: "Consumption Support Grant Dashboard",
      type: "CSGSuperset",
      pdfType: 'CSGSuperset',
      description: 'Distribution across Section 2 questions for all didis who have been rejected in PAT Section 2 ',

      content: <CSGSuperset slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    },
    {
      id: 7,
      name: "Livelihood Mapping Dashboard",
      type: "LHPSuperset",
      pdfType: 'LHPSuperset',
      description: 'Progress of selection process across various stages',
      content: <LHPSuperset slide={slide} widgetIsCollapsed={widgetIsCollapsed} />,
    },
  ]
  const dashComponent = widgetArray?.find((i) => i.id === widgetChange.id);
  const childRef = useRef();

  const handlelWidgetChange = (value) => {
    setWidgetChange(value)
    const widgetType = widgetArray.find(i => i.id === value.id)?.type
    setCurrentWidgetType(widgetType)
  }

  return (
    <div className="main-page analytics-dashboard fixed-header">
      <Header />
      <div className="main-section">
        <div className={`content-section ${widgetIsCollapsed ? 'content-section-collapsed' : 'content-section-expanded'}`}>

          <div className="map-section-outer">
            <div
              className={`${dashComponent?.type === 'map' ? "map-section h550" : "map-section"} ${isFullScreen ? "full-screen-map" : ""}`}
            >
              <div className="map-section-head">
                <div className="map-top-left">
                  <div className="map-title">{dashComponent?.name}</div>
                </div>
              </div>

              {sectionToggle.includes("Map") || sectionToggle.includes("Summary") ||
                sectionToggle.includes("Chart") && (
                  <React.Fragment>
                    <div
                      className={
                        slide ? " map-section-body" : " jc-c map-section-body"
                      }
                    >
                      {!isError && dashComponent.content}
                      {isError && <FallbackComp />}
                    </div>
                  </React.Fragment>
                )}
            </div>
            {isLeftArrow && <button type="button" className="slick-arrow slick-prev dashboard-button" onClick={() => childRef.current.dashboardPrev()}></button>}
            {isRightArrow && <button type="button" className="slick-arrow slick-next dashboard-button" onClick={() => childRef.current.dashboardNext()}></button>}
          </div>
        </div>
        <div className="slider-section">
          <WidgetSlider
            setIsError={setIsError}
            setWidgetChange={value => handlelWidgetChange(value)}
            widgetChange={widgetChange}
            setSlide={setSlide}
            setStateName={setStateName}
            setDistrictName={setDistrictName}
            setToggleType={setToggleType}
            setSummary={setSummary}
            type={dashComponent?.type}
            roleName={roleName}
            setSummaryRole={setSummaryRole}
            setQuestion={setQuestion}
            setShowExcelCta={setShowExcelCta}
            setSourceData={setSourceData}
            setSectionToggle={setSectionToggle}
            ref={childRef}
            widgetType="analytics"
            setWidgetIsCollapsed={setWidgetIsCollapsed}
            widgetIsCollapsed={widgetIsCollapsed}
          />
        </div>
      </div>
    </div>
  );
}

export default AnalyticsDashboard;