import { getResponse } from "../../pages/Dashboard/conifg";
import { ROLE_TYPE } from "./constant";
import { commonService } from "../../services/common";

export const dropDownTransformer = (data, role) => {
  let arr = [];
  arr = data?.[getResponse[role]]?.map((item) => ({
    value: item.name,
    id: item.id,
    name: item.name
  }));

  return arr;
};

export const handleDropDownShow = (role) => {
  if (role === ROLE_TYPE.NATIONAL) {
    return {
      showState: true
    }
  } else if (role === ROLE_TYPE.STATE) {
    return {
      showState: true,
      showDistrict: true,
      showBlock: false,
      showVillage: false
    }
  } else if (role === ROLE_TYPE.DISTRICT) {
    return {
      showDistrict: true,
      showBlock: true,
      showVillage: false
    }

  } else if (role === ROLE_TYPE.BLOCK) {
    return {
      showBlock: true,
      showVillage: true,
    }
  }
}
export const getDisplayName = () => {
  const { roleName, referenceId: refId , federationDetail} =
    JSON.parse(localStorage.getItem("userAuth")) || "";
  if (roleName === "Block") {
    return (federationDetail?.blockName + " , " + federationDetail?.districtName + " , " + federationDetail?.stateName)

  } else if (roleName === "District") {
    return (federationDetail?.districtName + ' , ' + federationDetail?.stateName)

  } else if( roleName === "National") {
    return "INDIA"
  }
  return refId?.[0]?.name
}

export const handleAuditTrail = (error, payload) => {
  if (error && error.response) {
    const sessionData = JSON.parse(localStorage.getItem('phoneNumberData')) || ''
    const mobileNo = sessionData && sessionData.phoneNumber || ''
    const { roleName, referenceId: refId, referenceName: refName } =
      JSON.parse(localStorage.getItem("userAuth")) || "";
    const auditTrailObj = [{
      "type": "API ERROR SPECIFIC",
      "url": payload?.config?.url,
      "mobileNumber": mobileNo,
      "deviceType": "WEB",
      "deviceId": null,
      "actionType": payload?.actionType,
      "actionStatusType": payload?.actionStatusType || "ERROR",
      "dataChangeDetails": payload?.dataChangeDetails || [],
      "message": error?.message,
      "timestamp": new Date().toISOString(),
      "stackTrace": error?.stack,
    }]
    try {
      commonService.sendAuditTrail(auditTrailObj)
    } catch (e) {
      console.error(e)
    }
  }
}

export const logAuditTrail = (payload, type) => {
  const actionType = payload?.config?.method?.toUpperCase()
  const status = payload?.data?.status?.toUpperCase()
  if (!payload?.config?.url.includes('audit-trail-service') && (type === "ERROR" || actionType === "GET" || true)) {
    const sessionData = JSON.parse(localStorage.getItem('phoneNumberData')) || ''
    const mobileNo = sessionData && sessionData.phoneNumber || ''
    const auditTrailObj = [{
      "type": "GENERIC",
      "url": payload?.config?.url,
      "mobileNumber": mobileNo,
      "deviceType": "WEB",
      "deviceId": null,
      "actionType": (payload?.config?.method)?.toUpperCase(),
      "actionStatusType": ["SUCCESS", "200"].includes(status) ? "SUCCESS" : "FAIL",
      "message": payload?.data?.message,
      "timestamp": new Date().toISOString(),
      ...((["ERROR", "FAIL"].includes(status) || type === "ERROR") && {"stackTrace": payload?.data?.data}),
    }]

    try {
      commonService.sendAuditTrail(auditTrailObj)
    } catch (e) {
      console.error(e)
    }
  }
};
