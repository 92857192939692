import axios from 'axios';
import GlobalConfig from './config/globalconfig';
import {logAuditTrail} from './components/utils/utils';


axios.interceptors.request.use((config) => {
  if (!GlobalConfig.isServer()) {
    const pwToken = localStorage.getItem('token') || '';
    if (pwToken) {
      config.headers[ 'authorization' ] = `Bearer ${pwToken}`;
      
    }
    config.headers[ 'source' ] = `WEB`;
  }
  return config;
}, err => Promise.reject(err));

export const logout = (is401) => {
  const url = window.location.pathname;

  if(is401) {
    localStorage.removeItem('token');
  }
  else {localStorage.removeItem('userAuth');
  localStorage.removeItem('token');
  console.log('ACCESS DENIED');
}
  return window.location.href = `/?auth=false&path=${url}`;
}

axios.interceptors.response.use(
  (res) => {
     if (res.status === 200) {
        // console.log(res.config.url, ':', res.status);
     }
     logAuditTrail(res);
     return res;
  },
  (err) => {
      // const originalRequest = err.config;
      if (
        err.response.status === 401 || err.response.status === 409
      ) {
      window.location.href = '/';
      logout(true);
      }
    if (err.response.status >= 500){
     // window.location.href = '/server-error';
    }
    logAuditTrail(err, "ERROR");
      console.log(err);
      return Promise.reject(err);
  }
);







