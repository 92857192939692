import React, { useEffect } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { commonService } from '../../../services/common';

const dashboardId = "3a7bcc0a-6ecd-474d-a74b-7372f1dda116";

async function getToken(widgetIsCollapsed) {

    const response = await commonService.supersetApi(dashboardId);
    const res = response?.data?.token;
    const supersetUrl = response?.data?.supersetBaseUrl;

  embedDashboard({
    id: dashboardId,  // given by the Superset embedding UI
    supersetDomain: supersetUrl,
    mountPoint: document.getElementById("superset-container"), // html element in which iframe render
    fetchGuestToken: () => res,
    dashboardUiConfig: { hideTitle: true }
  });

  const iframe = document.querySelector("iframe");
  if (iframe) {
    iframe.style.width = widgetIsCollapsed ? '69vw' : "92vw"; // Set the width as needed
    iframe.style.minHeight = '75vh'; // Set the height as needed
  }
}

const HamletSurveySupersetDashboard = ({widgetIsCollapsed}) => {
  useEffect(() => {
    getToken(widgetIsCollapsed);
  });

  return (
    <div className="App">
      <div id='superset-container'></div>
    </div>
  );
}

export default HamletSurveySupersetDashboard;