import React from "react";
import rightArrow from '../../../../assets/images/summary-arrow.svg'

const SummaryData = ({ slide, userRole, toggleSlide, summary, type, roleName }) => {

  return (
    <div className={slide ? "dismiss-test" : "w-auto"}>
      {['Block', 'District'].includes(roleName) && type === 'map' ? null : <div
        className={
          type === 'map' ? slide
            ? "right-summary-btn right-summary-btn-small"
            : "right-summary-btn right-summary-btn-big"
            :
            slide
              ? "right-summary-btn right-summary-btn-small top-class-arr"
              : "right-summary-btn right-summary-btn-big top-class-arr"
        }
        onClick={() => toggleSlide()}
      >
        {slide ? <img src={rightArrow} height="17" width="14" alt='right-arrow' /> : "< Summary"}
      </div>}
      <div className={`${slide ? "dismiss" : `selected ${['Block', 'District'].includes(roleName) && type === 'map' ? 'remove-translate' : ''}`} ${(['Block', 'District'].includes(roleName) && type === 'map') ? `full-width ${['State', 'National', 'District', 'Block'].includes(userRole) ? 'topPlus' : ''}` : type === 'map' ? '' : 'top-class'}`}>


        <table
          className="generic-table"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              {summary?.heading?.map((heading, i) => (
                <th key={i} scope="col">{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>

            {summary?.summaryValues}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SummaryData;
