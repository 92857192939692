import React, { useState, useEffect } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import './style.scss';
import { commonService } from '../../../../services/common';
import { useDispatch, useSelector } from 'react-redux';
import { setApiError, showLoaderAction } from '../../../../storeManager/common/actions';
import { catchErrorHandler, debounce } from '../../../../services/commonUtils';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import SearchBar from '../../../SearchBar';
import didiIcon from '../../../../assets/images/female.svg'
import successIcon from '../../../../assets/images/successIcon.svg';
import failIcon from '../../../../assets/images/red-info-icon.svg'




const ReassignSmallGroupModal = ({ modalParameters: { selectedDidis, callBackFn, activeUpcmId, modalBodyText, activeSmallGroup , activeUpcmName}, closePopup }) => {
    const dispatch = useDispatch();
    const { isApiError } = useSelector(state => state.commonReducer)

    const [smallGroupSearch, setSmallGroupSearch] = useState('')
    const [sgSearchList, setSGSearchList] = useState([])
    const [smallGroupList, setSmallGroupList] = useState([])
    const [selectedSG, setSelectedSG] = useState(null)

    useEffect(() => {
        fetchSmallGroupList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearchFilter = (value) => {

        setSmallGroupSearch(value)
        const  filteredList = smallGroupList.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
        setSGSearchList(filteredList)
    }

    const fetchSmallGroupList = async () => {
        dispatch(showLoaderAction(true));
        try {
            const payload = {
                userList: [activeUpcmId]
            }
            const res = await commonService.smallGroupList(payload);
            const resObj = res.data;


            if (res?.status === 200) {
                let updatedList = resObj?.data.map(_s => {
                    return {
                        id: _s.id,
                        name: _s.name,
                        beneficiaryCount: _s.beneficiaryCount
                    }
                })
                setSmallGroupList(updatedList)

                setSGSearchList(updatedList)
                
            }
            dispatch(showLoaderAction(false));


        } catch (error) {
            dispatch(showLoaderAction(false));

            catchErrorHandler(error, dispatch(setApiError()));
        }

    }

    const handleOptionChange = (e) => {
        setSelectedSG(e.target.value)
    }
    const ReassignDidiFromSmallGroup = async () => {
        try {
            dispatch(showLoaderAction(true));
            const payload = {
                "beneficiaryIds": selectedDidis,
                "sourceSmallGroupId": activeSmallGroup?.id,
                "action": "REASSIGN",
                "targetSmallGroupId": selectedSG
            }
            const response = await commonService.smallGroupDidiMapping(payload);
            const res = response.data;
            if (res?.message === "SUCCESS") {

                dispatch(showLoaderAction(false));
                if(res?.data?.status) {}

                dispatch(openModal(MODALS.SUCCESS_MODAL,
                    {
                        parentClass: "center-modal confirmation-modal",
                        info: res?.data?.message,
                        icon: (res?.data?.status) ? successIcon: failIcon,
                        showActionBtn: false
                    }))

                    
                    callBackFn()

            } else {

                dispatch(showLoaderAction(false));

            }

        } catch (error) {
            dispatch(showLoaderAction(false));

            catchErrorHandler(error, dispatch(setApiError()), {
                actionType: "UPDATE",
                actionStatusType:"ERROR",
                dataChangeDetails: [{
                    "entityId": selectedDidis,
                    "entityName": "Didi",
                    "fieldName": "Small Group Id",
                    "oldValue": activeSmallGroup?.id,
                    "newValue": selectedSG
                }
                ]
            });
        }
    }

    if(isApiError) {
        closePopup()
    }
    return <div className='sg-reassign-container'>
        <ModalBody>
            {smallGroupList.length > 1 ?

                <>
                    <SearchBar
                        handleOnChange={debounce((e) => handleSearchFilter(e.target.value), 500)}
                        placeholder='Search Small Group'
                       
                    />
                    <div className='sg-card-wrap'>
                        <p className='sg-heading'>Select Group ({activeUpcmName})</p>
                        {
                            sgSearchList?.length > 0 && sgSearchList.map(item => {
                                if (item.id !== activeSmallGroup.id)
                                    return <div className='sg-item'>
                                        <label>
                                            <input
                                                type='radio'
                                                value={item.id}
                                                checked={parseInt(selectedSG) === item.id}
                                                onChange={handleOptionChange}
                                            />
                                        </label>
                                        <div className='sg-item-name'>
                                            <span class="op-name">{item.name} </span>
                                            <div className='sg-side-content'>
                                                <img src={didiIcon} alt="didiIcon"/>
                                                <span class="count">{item.beneficiaryCount}</span>
                                            </div>
                                        </div>
                                    </div>
                            })

                        }

                    </div>

                </>
                :
                <div className='no-sg-list'>
                    <img src={didiIcon} alt="didiIcon"/>
                    <p> No small groups are created for UPCM {activeUpcmName}</p>
                </div>
            }

        </ModalBody>
        <ModalFooter>
            <button className="secondary-btn ml" onClick={closePopup}>
                Cancel
            </button>
            <button disabled={!selectedSG} className="primary-btn" onClick={ReassignDidiFromSmallGroup}>
                Reassign
            </button>
        </ModalFooter>
    </div>


}
export default ReassignSmallGroupModal